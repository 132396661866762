/*    
<summary>
   This functional common component provides us custom input box & has error handler that shows validation error. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { injectIntl } from "react-intl";
import FormikFormErrorHandler from "./FormikFormErrorHandler";
import { useField } from "formik";
import { bytesToMegabytes } from "../../constants/constants";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";
import { formatMessage } from "../../translations/format-message";

interface IProps {
  children: React.ReactNode;
  className: string;
  errclassname: string | undefined;
  "data-testid"?: string;
  intl: any; //IntlConfig;
  name: string;
  index: number;
  onBlur: () => void;
  onChange: () => void;
  handleFileUpload: () => void;
  setFile: (file: any) => void;
  deleteFile: (index:number) => void;
  pricingValue: number;
  placeholder: string;
  value: string;
  disabled: boolean;
  accept: string;
}

const FormikFormPricingImageInput = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${meta.error && meta.touched ? "error" : ""}`;
  const _placeholder = props.placeholder
    ? props.intl.formatMessage({ id: props.placeholder })
    : props.placeholder;
  const options = {
    maxSizeMB: 2.5, // Max size in MB
    // maxWidthOrHeight: 800, // Max width or height
    useWebWorker: true, // Optional: use web worker for performance
  };

  return (
    <>
      <input
        {...field}
        {...props}
        placeholder={_placeholder}
        className={props.className + " " + errorClass}
        disabled={props.disabled}
        type="file"
        accept={props.accept}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const reader = new FileReader();
          if (e.target.files != null && e.target.files.length > 0) {
            let file = e.target?.files[0];
            reader.onload = props.handleFileUpload;
            reader.onload = async () => {
              const base64String = reader?.result;
              console.log(Math.floor(bytesToMegabytes(file.size)));
              if (Math.floor(bytesToMegabytes(file.size)) >= 5) {
                toast.error(formatMessage("file_size_error"));
                helpers.setValue("");
                return;
              } else {
                props.setFile({
                  ImageName: `${props.index}_Pricing.${file?.name
                    ?.split(".")
                    ?.pop()}`,
                  PricingValue: props.index,
                  File:
                    bytesToMegabytes(file.size) >= 2.5
                      ? imageCompression(file, options)
                      : file,
                  IsFileCompressed:
                    bytesToMegabytes(file.size) >= 2.5 ? true : false,
                  IsGenerateHtml: true,
                  Base64: base64String,
                });
              }
            };
            reader.readAsDataURL(e?.target?.files[0]);
            helpers.setValue(e.target?.value);
          } else {
            props.deleteFile(props.index);
            helpers.setValue("");
          } 
        }}
      />
      <FormikFormErrorHandler intl={props.intl} meta={meta} />
    </>
  );
};

export default injectIntl(FormikFormPricingImageInput);
